export const SET_PROFIRELINE = "setProfireLine";
export const SET_PROFIRE = "setProfire";
export const SET_ACCESSTOKEN = "setAccessToken";
export const SET_TITLE = "setTitle";
export const SET_SUB_TITLE = "setSub_Title";
export const SET_URLCALLBACK = "setUrlCallback";

export default {
  state: {
    profire_line: [],
    profire: [],
    title: [],
    accesstoken: [],
    urlcallback: [],
  },
  getters: {
    getProfireLine(state) {
      return state.profire_line;
    },
    getProfire(state) {
      return state.profire;
    },
    getTitle(state) {
      return state.title;
    },
    getSub_Title(state) {
      return state.sub_title;
    },
    getAccessToken(state) {
      return state.accesstoken;
    },
    getUrlCallBack(state) {
      return state.urlcallback;
    },
  },
  actions: {
    [SET_PROFIRELINE](state, payload) {
      state.commit(SET_PROFIRELINE, payload);
    },
    [SET_PROFIRE](state, payload) {
      state.commit(SET_PROFIRE, payload);
    },
    [SET_TITLE](state, payload) {
      state.commit(SET_TITLE, payload);
    },
    [SET_SUB_TITLE](state, payload) {
      state.commit(SET_SUB_TITLE, payload);
    },
    [SET_ACCESSTOKEN](state, payload) {
      state.commit(SET_ACCESSTOKEN, payload);
    },
    [SET_URLCALLBACK](state, payload) {
      state.commit(SET_URLCALLBACK, payload);
    },
  },
  mutations: {
    [SET_PROFIRELINE](state, payload) {
      state.profire_line = payload;
    },
    [SET_PROFIRE](state, payload) {
      state.profire = payload;
    },
    [SET_TITLE](state, payload) {
      state.title = payload;
    },
    [SET_SUB_TITLE](state, payload) {
      state.sub_title = payload;
    },
    [SET_ACCESSTOKEN](state, payload) {
      state.accesstoken = payload;
    },
    [SET_URLCALLBACK](state, payload) {
      state.urlcallback = payload;
    },
  },
};
