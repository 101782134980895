<template>
  <div class="card-body mt-2 white">
    <div class="title">
      <i class="bi bi-receipt"
        ><span style="font-size: 14px; margin-left: 5px; color: black"
          >รายละเอียดการดำเนินการ</span
        ></i
      >
    </div>

    <div class="body-text mt-5">
      <!--begin::Row-->
      <div class="row mb-12 mt-2 w-100">
        <!--begin::Label-->
        <label class="col-12 text-muted">{{ comment.status_detail }}</label>
        <!--end::Label-->
        <!--begin::Col-->

        <!--end::Col-->
      </div>
      <!--end::Row-->

      <!--begin::Row-->
      <div class="row mb-7 mt-2">
        <div
          class="col-12"
          v-for="(image, key) in appeals.image_appeal"
          :key="key"
        >
          <img
            :src="image.src"
            class="mt-2"
            style="object-fit: cover; width: 100%"
            alt="..."
          />
        </div>
      </div>
      <!--end::Row-->
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";
export default defineComponent({
  name: "Appeal-Detail-Detail",
  props: ["appeals"],
  setup(props) {
    const comment = computed(() => {
      const date = props.appeals.timeline.filter(
        (item) => item.status === "เสร็จสิ้น"
      );

      return date[0];
    });

    return {
      comment,
    };
  },
});
</script>

<style scoped></style>
