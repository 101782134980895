import ApiService from "@/service/ApiService";

export default function useAppeal() {
  const SendAppeal = async (data) => {
    return await ApiService.post("appeal", data);
  };

  const SendRating = async (data) => {
    return await ApiService.put("rating/" + data.id, data);
  };

  const getAppeals = async () => {
    return await ApiService.get("appeal");
  };

  const getAppeal = async (id) => {
    return await ApiService.get("appeal/" + id);
  };

  return {
    SendAppeal,
    getAppeals,
    SendRating,
    getAppeal
  };
}
