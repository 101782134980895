import ApiService from "@/service/ApiService";
import JwtService from "@/service/JwtService";
// action types

export const LOGIN = "login";

// mutation types

export const SET_AUTH = "setUser";

const state = {
  user: {},
};

const getters = {
  getToken(state) {
    return state.user.token;
  },
};

const actions = {
  [LOGIN](context, credentials) {
    JwtService.destroyToken();
    return new Promise((resolve, reject) => {
      ApiService.post("login", credentials)
        .then(({ data }) => {
          // console.log("Here what post returns", data);
          if (data) {
            context.commit(SET_AUTH, data);
            resolve();
          }

        })
        .catch(() => {
          reject();
        });
    });
  },
};

const mutations = {
  [SET_AUTH](state, user) {
    state.user = user;

    JwtService.saveToken(state.user.access_token);
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
