import { createStore } from "vuex";

import ProfireLine from "@/store/modules/ProfireLine";
import AuthModule from "@/store/modules/AuthModule";
import AppealModel from "@/store/modules/AppealModel";
const store = createStore({
  modules: {
    ProfireLine,
    AuthModule,
    AppealModel,
  },
});

export default store;
